const IconfontWebpackPlugin = require('iconfont-webpack-plugin');

module.exports = {
	siteMetadata: {
		title: 'The Muffin Tops Crew 🎶',
		description:
			'Die Muffin Tops kombinieren funky Rockmusik mit tanzbaren Rhythmen, jazziger Verspieltheit und ironisch-melancholischen Texten. Die Musik stammt aus der Feder von fünf leidenschaftlichen Musikern zwischen Ende Zwanzig und Anfang Dreißig.',
		keywords: 'the muffin tops funky rockmusik jazz ironisch frankfurt offenbach bad-nauheim',
		titleTemplate: '%s | The Muffin Tops | Finest Funk from Frankfurt',
		url: 'https://themuffintops.de',
		image: 'logo.png',
		owner: 'The Muffin Tops',
		facebookAppID: '',
		author: {
			name: 'The Muffin Tops',
			url: 'https://themuffintops.de',
		},
	},
	pathPrefix: '/',
	plugins: [
		'gatsby-plugin-react-helmet',
		{
			resolve: 'gatsby-source-filesystem',
			options: {
				name: 'assets',
				path: `${__dirname}/src/assets`,
			},
		},
		{
			resolve: 'gatsby-source-filesystem',
			options: {
				name: 'content',
				path: `${__dirname}/content`,
			},
		},
		'gatsby-transformer-remark',
		'gatsby-transformer-sharp',
		'gatsby-plugin-sharp',
		'gatsby-plugin-typescript',
		{
			resolve: 'gatsby-plugin-manifest',
			options: {
				name: 'themuffintops',
				short_name: 'tmt',
				start_url: '/',
				background_color: 'transparent',
				theme_color: '#1b2727',
				display: 'minimal-ui',
				icon: 'src/assets/favicon/the-muffin-tops-icon.png',
			},
		},
		{
			resolve: 'gatsby-plugin-sass',
			options: {
				postCssPlugins: (loader) => [
					new IconfontWebpackPlugin({
						resolve: loader.resolve,
						fontNamePrefix: 'the-muffin-tops-',
						enforcedSvgHeight: 3000,
						modules: false,
					}),
				],
				precision: 8,
			},
		},
		{
			resolve: 'gatsby-source-instagram-all',
			options: {
				access_token:
					'IGQWRNV1RRVkxyT3lJQ1RWaVk0dkpoNjQxQjRfNXoxYnpZAcDlSbVNKQk12Q2VCT3JQenZA0TFF1MkJGdFlhZAVJwTm5pUHhjWnJMaVJMaE9WSXk5M1hxdlpjdGNWT1YxSHRiNmFuMDBmNldFVGh1UVB0WXFmQ05PMm8ZD',
			},
		},
		{
			resolve: 'gatsby-plugin-matomo',
			options: {
				siteId: '1',
				matomoUrl: 'https://matomo.danielkoch.de',
				siteUrl: 'https://themuffintops.de',
				disableCookies: true,
			},
		},
		'gatsby-plugin-offline',
	],
};
